import request from "@/utils/request";

export const getList = (page,size,params)=>{
    return request({
        url:'/api/hado/coupon/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/hado/coupon/detail',
        method:'get',
        params:{id}
    })
}

export const submit = (data)=>{
    return request({
        url:'/api/hado/coupon/submit',
        method:'post',
        data:data
    })
}

export const remove = (id)=>{
    return request({
        url:'/api/hado/coupon/remove',
        method:'get',
        params:{id}
    })
}

export const sendToUser = (coupon_id,user_id)=>{
    return request({
        url:'/api/hado/coupon/send-to-user',
        method:'get',
        params:{coupon_id:coupon_id,user_id:user_id}
    })
}

export const initQrRecord = (coupon_id,total_init)=>{
    return request({
        url:'/api/hado/coupon/init-qr-record',
        method:'get',
        params:{coupon_id:coupon_id,total_init:total_init}
    })
}

export const downloadQrCode = (coupon_id)=>{
    return request({
        url:'/api/hado/coupon/download-qr-code',
        method:'get',
        params:{coupon_id},
        responseType:'blob'
    })
}
