<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                    <el-row>
                        <el-form-item label="优惠券名称:">
                            <el-input v-model="search_option.coupon_name"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" icon="el-icon-document-add" size="mini" @click="onCreateTap">新增</el-button>
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border style="width:100%;">
                    <el-table-column prop="id" label="ID" width="150"></el-table-column>
                    <el-table-column prop="coupon_name" label="优惠券名称"></el-table-column>
                    <el-table-column prop="remark" label="备注"></el-table-column>
                    <el-table-column prop="coupon_type" label="类型" width="88">
                        <template #default="scope">
                            <span :style="{color:(params_map['coupon_type']&&params_map['coupon_type'][scope.row.coupon_type])?params_map['coupon_type'][scope.row.coupon_type].color:''}">{{(params_map['coupon_type'] && params_map['coupon_type'][scope.row.coupon_type])?params_map['coupon_type'][scope.row.coupon_type].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="with_money" label="优惠金额" align="right" width="88"></el-table-column>
                    <el-table-column prop="price_down_limit" label="价格下限" align="right" width="88"></el-table-column>
                    <el-table-column prop="" label="已发放/总量" align="center" width="128">
                        <template #default="scope">
                            <div style="text-align: center;">
                                <span>{{scope.row.sends}}</span>/<span>{{scope.row.quota}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="二维码">
                        <template #default="scope">
                            <el-button v-if="scope.row.qr_code_url" type="text" @click="onSeeQrCodeTap(scope.row)">二维码</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="创建时间" :formatter="elTimeStampFormat" width="136"></el-table-column>
                    <el-table-column prop="" label="操作" width="300">
                        <template #default="scope">
                            <el-button type="text" size="mini" @click="onViewTap(scope.row)">查看</el-button>
                            <el-button type="text" size="mini" @click="onEditTap(scope.row)">编辑</el-button>
                            <el-button type="text" size="mini" @click="onRemoveTap(scope.row)">删除</el-button>
                            <el-button type="text" size="mini" @click="onInitQrRecordTap(scope.row)">生成唯一码</el-button>
                            <el-button type="text" size="mini" @click="onDownloadQrCodeTap(scope.row)">下载二维码</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog v-model="dialogQrVisible" :title="dialogQrTitle" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <div style="text-align:center;padding:20px;">
                <vue-qrcode :value="dialogQrUrl" :options="{ width: 300 }"></vue-qrcode>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode'
    import {getList, initQrRecord, remove,downloadQrCode} from "@/api/hado/coupon";
    import {getParams} from "@/api/hado/param";
    import {formatTimestamp} from "@/utils/time";
    const param_list = ['coupon_type']
    export default {
        name: "coupon-list",
        components:{VueQrcode},
        data(){
            return{
                loading:false,
                search_form_name:'coupon_search',
                page_option:{page:1,size:30,total:0},
                search_option:{},
                page_list:[],
                params:{},
                params_map_pure:{},
                params_map:{},

                dialogQrTitle:'',
                dialogQrUrl:'',
                dialogQrVisible:false,
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            onViewTap(row) {
                console.log('onViewTap:', row)
                this.$router.push({path:'/b/coupon-detail',query:{action:'view',id:row.id}})
            },
            onEditTap(row) {
                console.log('onEditTap:', row)
                this.$router.push({path:'/b/coupon-detail',query:{action:'edit',id:row.id}})
            },
            onCreateTap() {
                console.log('onCreateTap')
                this.$router.push({path:'/b/coupon-detail',query:{action:'add'}})
            },
            onRemoveTap(row) {
                this.$messageBox.confirm('确定删除当前数据?', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                }).then(() => {
                    remove(row.id).finally(() => {
                        this.reloadPage()
                    })
                })
            },
            onTellDialogClose() {
                this.dialogFormVisible = false;
                this.dialogFormRowId = 0;
                this.reloadPage()
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            onSeeQrCodeTap(row){
                this.dialogQrTitle='领券码:'+ row.code;
                // this.dialogQrUrl='http://www.banbansx.com/hado/coupon?code='+row.code;
                this.dialogQrUrl=row.qr_code_url;
                console.log('this.dialogQrUrl:',this.dialogQrUrl)
                this.dialogQrVisible=true
            },
            onInitQrRecordTap(row){
                initQrRecord(row.id,10)
            },
            onDownloadQrCodeTap(row){
                downloadQrCode(row.id).then(res=>{
                    const blob = new Blob([res.data]);
                    if(res.data.type!=="application/force-download") {
                        console.log("文件类型不对:",blob.type)
                        this.$message.warning("你无法下载订单")
                        return
                    }
                    const fileName = `优惠券二维码-id-${row.id}.zip`;
                    const elink = document.createElement('a');
                    elink.download = fileName
                    elink.style.display = 'none';
                    elink.href= URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click()
                    URL.revokeObjectURL(elink.href);//释放URL 对象
                    document.body.removeChild(elink);
                }).catch(err=>{this.$message.error('下载异常');console.error(err)})
            },
        }
    }
</script>

<style scoped>

</style>
